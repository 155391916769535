body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  max-width: 100vw;
  max-height: 100%;
  width: 100%;
  height: 100%;
  position: relative;
}
