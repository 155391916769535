@font-face {
  font-family: "Open Sans";
  src: url("fonts/OpenSans-Italic-VariableFont_wdth,wght.ttf")
    format("truetype");
}
@font-face {
  font-family: "HK Grotesk Medium";
  src: url("fonts/hk-grotesk.medium-legacy.ttf") format("truetype");
}
.form-conteiner {
  display: flex;
  flex-direction: row;
}
.form-img {
  flex: 1;
}
.form-img img {
  height: 70%;
  width: 80%;
  margin: 1.25rem;
  padding: 1.25rem;
  border-radius: 1.5625rem;
}
.formulario {
  margin: 1.25rem 2.5rem;
  padding: 1.25rem;
  align-items: center;
  justify-content: center;
  position: relative;
  word-spacing: 0.1875rem;
}
.form-titulo {
  font-size: 1.8rem;
  color: #255b2c;
  font-family: "Open Sans";
  text-transform: uppercase;
  padding: 0.0625rem;
}
.form {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.formulario-input {
  height: 26.25rem;
  width: 31.25rem;
  display: flex;
  flex-direction: column;
  position: relative;
}
input.form-input {
  justify-content: flex-end;
}
.form-input {
  font-size: 1.125rem;
  font-family: "HK Grotesk Medium";
  width: 18.75rem;
  border: none;
  border-bottom: 0.0625rem solid #e7a83e;
  color: #255b2c;
  padding: 0.4375rem 0rem 0.4375rem 0.625rem;
  margin-left: 1.25rem;
}
.form-input:focus {
  outline: none;
}
.form-label {
  justify-content: space-between;
  color: #255b2c;
  font-family: "HK Grotesk Medium";
  font-weight: bolder;
  padding: 0.625rem 0.25rem 0.625rem 1.25rem;
  font-size: 1.125rem;
}
.form-textarea {
  display: flex;
  margin-right: 1.25rem;
}
.form-textarea-box {
  padding: 0.1875rem;
  max-height: 6.25rem;
  min-height: 6.1875rem;
  max-width: 17.8125rem;
  min-width: 17.75rem;
  font-family: "HK Grotesk Medium";
  font-size: 0.9375rem;
  color: #255b2c;
  border-color: #e7a83e;
  border-style: 0.0625rem solid;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
}
.form-label-textarea {
  color: #255b2c;
  font-family: "HK Grotesk Medium";
  font-weight: bolder;
  padding: 0.3125rem 0.25rem 0.625rem 0.3125rem;
  font-size: 1.125rem;
}
.buttom-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}
.form-button {
  background-color: #fff;
  color: #255b2c;
  border-color: #e7a83e;
  border-style: solid;
  cursor: pointer;
  font-family: "Open Sans";
  font-weight: bolder;
  border-radius: 0.1875rem;
}
.form-button:hover {
  box-shadow: 0 0 0.25rem 0 gray;
}
@media (max-width: 1024px) {
  .form-conteiner {
    flex-direction: column;
    align-items: center;
  }
  .formulario {
    height: max-content;
  }
}
@media (max-width: 768px) {
  .form-conteiner {
    flex-direction: column;
  }
  .form-img {
    display: flex;
    align-items: center;
    width: auto;
    justify-content: center;
  }
  .form-img {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .form-img img {
    width: 60%;
    margin: 0.9375rem;
    align-items: center;
  }
  .formulario {
    margin: 0.3125rem;
    padding: 0rem;
    justify-content: center;
  }
  .formulario-input {
    width: auto;
    height: auto;
  }
  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .form-titulo {
    font-size: 1.3rem;
    margin-left: auto;
    margin-left: auto;
    text-align: center;
  }
  .form-input {
    width: auto;
  }
  .form-label {
    padding: 0rem;
    font-size: 1rem;
  }
  .form-label-textarea {
    font-size: 1rem;
  }
  .form-textarea-box {
    max-width: 14.25rem;
    min-width: 14rem;
  }
  .buttom-wrapper {
    margin: auto;
  }
}
@media (max-width: 640px) {
  .form-conteiner {
    flex-direction: column;
  }
  .form-img {
    display: flex;
    align-items: center;
    width: auto;
    justify-content: center;
  }
  .form-img img {
    width: 80%;
    height: 80%;
    margin: 0.9375rem;
  }
  .formulario {
    margin: 0.3125rem;
    padding: 0rem;
    justify-content: center;
  }
  .formulario-input {
    width: auto;
    height: auto;
  }
  .form {
    display: flex;
    flex-direction: column;
  }
  .form-titulo {
    font-size: 1.3rem;
    margin-left: 1.2rem;
    justify-content: center;
  }
  .form-label {
    padding: 0rem;
    font-size: 1rem;
    margin-left: 1rem;
  }
  .form-label-textarea {
    display: none;
  }
  .form-textarea-box {
    width: 6em;
    margin-left: 3rem;
  }
  .buttom-wrapper {
    margin: auto;
  }
}
