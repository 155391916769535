@font-face {
  font-family: "Open Sans";
  src: url("fonts/OpenSans-Italic-VariableFont_wdth,wght.ttf")
    format("truetype");
}
.navlink-menu {
  display: flex;
  justify-content: space-evenly;
  background-color: #e7a83e;
  padding: 1rem;
  position: sticky;
  z-index: 2;
  top: 0;
}
.navlink-button {
  display: flex;
  list-style: none;
  cursor: pointer;
  align-items: center;
}
#a-button {
  text-decoration: none;
  color: #fff;
  margin-right: 1em;
  font-family: "Open Sans";
  position: relative;
  text-transform: uppercase;
}
#a-button:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.1875rem;
  bottom: 0;
  left: 0;
  background-color: #fff;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

#a-button:hover:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}
@media (max-width: 375px) {
  .navlink-menu {
    position: sticky;
  }
}
