.servicos-conteiner {
  display: flex;
  flex-direction: row;
  flex: 1;
}
.servicos-img img {
  height: 43.75rem;
  width: 43.75rem;
  margin: 1.25rem;
  padding: 1.25rem;
  border-radius: 1.5625rem;
}
.servicos-block {
  flex: 2;
  list-style: none;
  margin: 1.25rem;
  padding: 2.5rem;
  align-items: center;
  justify-content: center;
  text-align: left;
  /* position: relative; */
  word-spacing: 0.1875rem;
  font-style: normal;
  font-family: "Open Sans";
}
@media (max-width: 1024px) {
  .servicos-conteiner {
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 768px) {
  .servicos-conteiner {
    display: flex;
    flex-direction: column;
  }
  .servicos-img {
    display: flex;
    width: auto;
    height: auto;
    justify-content: center;
  }
  .servicos-img img {
    width: 21.875rem;
    height: 21.875rem;
    align-items: center;
  }
}
@media (max-width: 425px) {
  .servicos-conteiner {
    display: flex;
    flex-direction: column;
  }
  .servicos-img {
    width: auto;
    height: auto;
    justify-content: center;
  }
  .servicos-img img {
    width: 15.6rem;
    height: 12.5rem;
    align-items: center;
  }
}
@media (max-width: 375px) {
  .servicos-conteiner {
    display: flex;
    flex-direction: column;
  }
  .servicos-img {
    width: auto;
    height: auto;
    justify-content: center;
  }
  .servicos-img img {
    width: 15.6rem;
    height: 12.5rem;
    align-items: center;
  }
}
